import { render, staticRenderFns } from "./widget.vue?vue&type=template&id=543b47ca&scoped=true&"
import script from "./widget.vue?vue&type=script&lang=js&"
export * from "./widget.vue?vue&type=script&lang=js&"
import style0 from "./widget.vue?vue&type=style&index=0&id=543b47ca&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "543b47ca",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ElementsOverlay: require('/home/ticketshoplv/public_html/ticketshop.ee/components/elements/Overlay.vue').default,ElementsLoader: require('/home/ticketshoplv/public_html/ticketshop.ee/components/elements/Loader.vue').default,HeaderLogo: require('/home/ticketshoplv/public_html/ticketshop.ee/components/Header/Logo.vue').default,HeaderLangSwitch: require('/home/ticketshoplv/public_html/ticketshop.ee/components/Header/LangSwitch.vue').default,Cart: require('/home/ticketshoplv/public_html/ticketshop.ee/components/Cart/Index.vue').default,CartMobile: require('/home/ticketshoplv/public_html/ticketshop.ee/components/Cart/Mobile.vue').default})
